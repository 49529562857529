
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { request as axios } from '@/util/request';
interface Form {
    knight_lang: number;//骑手抢单范围
    knight_time: number;//骑手抢单时效
    knight_dance: number;//基础配送距离
    knight_money: number;//基础配送费
    knight_add_money: number;//额外配送费
    knight_bfb: number;//百分比
  //kinght_time: Moment[];//时间
}
function getFormData() {
  return {
    knight_lang: 5,//骑手抢单范围
    knight_time: 1,//骑手抢单时效
    knight_dance: 5,//基础配送距离
    knight_money: 8.00,//基础配送费
    knight_add_money: 0.00,//额外配送费
    knight_bfb:0
    //kinght_time: [],
  };
}
@Component({
    name:'Knightset'
})
export default class Knightset extends Vue{
    is_loading: boolean = false;
    form: Form = getFormData();
    knight_lang_err: boolean=false;
    knight_time_err: boolean=false;
    knight_dance_err: boolean=false;
    knight_money_err: boolean=false;
    knight_add_money_err: boolean=false;
    knight_bfb_err: boolean=false;
    // 验证是否为纯数字
  validateNumber(number: string) {
    const reg = /^[1-9]\d*$/;
    return reg.test(number);
  }
  /**
   * @description 验证是否最多两位小数的数字
   * @param {string} number
   * @returns {number}
   */
  validateDouble(number: string): boolean {
   // if (Number(number) === 0) return false;
    return /^([0-9]\d*)(\.\d{2})?$/.test(number);
  }
    //输入框改变校验
    inputChange(type: string){
        if(type=='knight_lang'){
            console.log(this.form.knight_lang,this.validateNumber(String(this.form.knight_lang)))
            if(String(this.form.knight_lang)==''||!this.validateNumber(String(this.form.knight_lang))){
                this.knight_lang_err=true
                return false
            }else{
                this.knight_lang_err=false
                return true
            }
        }else if(type=='knight_time'){
            if(String(this.form.knight_time)==''||!this.validateNumber(String(this.form.knight_time))){
                this.knight_time_err=true
                return false
            }else{
                this.knight_time_err=false
                return true
            }
        }else if(type=='knight_dance'){
            if(String(this.form.knight_dance)==''||!this.validateNumber(String(this.form.knight_dance))){
                this.knight_dance_err=true
                return false
            }else{
                this.knight_dance_err=false
                return true
            }
        }else if(type=='knight_money'){
            if(String(this.form.knight_money)==''||!this.validateDouble(String(this.form.knight_money))){
                this.knight_money_err=true
                return false
            }else{
                this.knight_money_err=false
                return true
            }
        }else if(type=='knight_add_money'){
            console.log(this.form.knight_add_money)
            if(String(this.form.knight_add_money)==''||!this.validateDouble(String(this.form.knight_add_money))){
                this.knight_add_money_err=true
                return false
            }else{
                this.knight_add_money_err=false
                return true
            }
        }else if(type=='knight_bfb'){
            if(String(this.form.knight_bfb)==''||!this.validateNumber(String(this.form.knight_bfb))){
                this.knight_bfb_err=true
                return false
            }else{
                this.knight_bfb_err=false
                return true
            }
        }
        else{
            return true
        }
    }
    //获取数据
    async getData(){
        const res=await axios.get('/boss/knight/setting')
        if(res.status==200){
            this.form.knight_lang=res.data.scope
            this.form.knight_time=res.data.time_limit
            this.form.knight_dance=res.data.base_distance
            this.form.knight_money=res.data.base_money
            this.form.knight_add_money=res.data.overstep_money
            this.form.knight_bfb=res.data.refund_commission_ratio
        }
        
    }
    //保存
    async save(){
        console.log(this.inputChange('knight_add_money'))
        if(!this.inputChange('knight_lang')||!this.inputChange('knight_time')||!this.inputChange('knight_dance')
        ||!this.inputChange('knight_money')||!this.inputChange('knight_add_money')||!this.inputChange('knight_bfb')){
            this.$message.error('请检查必填项')
        }else{
            //保存
            const body={
                scope:this.form.knight_lang,
                time_limit:this.form.knight_time,
                base_distance: this.form.knight_dance,
                base_money: this.form.knight_money,
                overstep_money:this.form.knight_add_money,
                refund_commission_ratio:this.form.knight_bfb
            }
            const res=await axios.post('/boss/knight/setting',body)
            if(res.status!=200){
                this.$message.error('保存失败')
            }else{
                this.$message.success('保存成功')
                this.getData()
            }
        }
    }
    async created(){
        await this.getData()
    }
}
